<template>
  <div
    class="flex w-full"
    :class="{ 'col-span-2': type === FieldType.TEXTAREA }"
  >
    <div
      v-if="type !== FieldType.TEXTAREA"
      class="w-8 h-[46px] rounded-l border items-center flex justify-center border-gray-200 border-r-0 bg-gray-50"
    >
      <component :is="offerFieldIcons[type]" />
    </div>
    <component
      :is="inputComponent.component"
      v-bind="inputComponent.props"
      class="w-full"
      :class="{ 'h-[46px]': type !== FieldType.TEXTAREA }"
      :placeholder="title"
      :name="name"
      :disabled="viewOnly"
      :data-cy="name"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, type Component } from "vue";
import { FieldType } from "@/enums/offers";
import { offerFieldIcons } from "@/helpers/constants/offers";
import LfCurrency from "@/components/ui/inputs/LfCurrency.vue";

const props = defineProps<{
  name: string;
  title: string;
  default?: string | number;
  type: FieldType;
  options?: Record<string, string> | string[];
  viewOnly: boolean;
}>();

const inputComponent = computed(() => {
  const componentType: Record<
    FieldType,
    { component: string | Component; props: Record<string, unknown> }
  > = {
    [FieldType.INTEGER]: {
      component: LfCurrency,
      props: {
        options: {
          currencyDisplay: "hidden",
          precision: 0,
          valueRange: { min: -999999999, max: 999999999 }
        }
      }
    },
    [FieldType.FLOAT]: {
      component: LfCurrency,
      props: {
        options: {
          currencyDisplay: "hidden",
          precision: 3,
          valueRange: { min: -999999999, max: 999999999 }
        }
      }
    },
    [FieldType.PERCENT]: {
      component: LfCurrency,
      props: {
        options: {
          currencyDisplay: "hidden",
          precision: 3,
          valueRange: { min: -100, max: 100 }
        }
      }
    },
    [FieldType.STRING]: { component: "lf-input", props: {} },
    [FieldType.URL]: { component: "lf-input", props: {} },
    [FieldType.CURRENCY]: {
      component: LfCurrency,
      props: { options: { currencyDisplay: "hidden", precision: 3 } }
    },
    [FieldType.OPTIONS]: {
      component: "lf-dropdown",
      props: {
        customButtonClassnames: {
          heightClassname: "h-12"
        },
        class: "mb-6",
        fullWidth: true,
        options: props.options ?? {}
      }
    },
    [FieldType.DATE]: { component: "lf-input", props: { type: "date" } },
    [FieldType.TEXTAREA]: { component: "lf-textarea", props: {} },
    [FieldType.DEFAULT]: { component: "lf-input", props: {} }
  };

  const component = componentType[props.type];
  if (!component) {
    return componentType[FieldType.DEFAULT];
  }
  return component;
});
</script>
